import {createContext} from 'react';
export interface HexomaticUserType {
  isPerformer: boolean;
  error_code: null | string;
  id: number;
  user_legacy_id: number;
  left_credits: number;
  left_premium_credits: number;
  premium_automation_credit: number | null;
  pricing_package: string | null;
  pricing_package_interval: string | null;
  pricing_package_count: number | null;
  stripe_subscription_id: number | null;
  left_premium_automation_credit: number | null;
  left_automation_credit: number | null;
  automation_credit: number | null;
  photo: string | null;
  billing_info?: {
    last_billing_date: string;
    next_billing_date: string;
  };
  promo_codes_count?: number | null;
  subscription_status?: 'active' | 'canceled';
}

export const HexomaticUserContext = createContext({
  hexomaticUser: {} as HexomaticUserType,
  setHexomaticUser: (hexomaticUser: HexomaticUserType) => {},
});
