import React, {useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import classNames from 'classnames';

import {SectionProps} from '../../types';
import {UserContext, UserType} from '../../helpers/contexts/user-context';
import {HexomaticUserContext, HexomaticUserType} from '../../helpers/contexts/hexomatic-user';

import Authentication from '../authentication';
import {Translate} from '../../components/translations';

import sectionBgImg from '../../images/section-bg.png';
import sectionDotsImg from '../../images/home/testimonials/dote-pattern.png';

import './styles.scss';

const Section = (props: SectionProps) => {
  const generateClassName = (props: SectionProps): string => {
    if (props.grey) {
      return 'grey_section_area';
    } else if (props.primary) {
      return 'blue_section_area';
    } else if (props.light) {
      return 'light_section_area';
    } else if (props.lightGrey) {
      return 'light_grey_section_area';
    } else if (props.lightBlue) {
      return 'light_blue_section_area';
    } else if (props.violetGradient) {
      return 'violet_section_area';
    } else if (props.dark) {
      return 'dark_section_area';
    } else if (props.smVioletLgLightBlue) {
      return 'sm_violet_lg_light_blue_section_area';
    } else if (props.smVioletLgLight) {
      return 'sm_violet_lg_light_section_area';
    } else if (props.halloween) {
      return 'halloween_section_area'
    } else {
      return 'light_section_area';
    }
  };

  const className = generateClassName(props);
  const [user, setUser] = useState({} as UserType);
  const [hexomaticUser, setHexomaticUser] = useState({} as HexomaticUserType);

  const isDesktop = useMediaQuery({minWidth: '1200px'});
  const isTablet = useMediaQuery({minWidth: '768px'});

  return (
    <UserContext.Provider value={{user, setUser}}>
      <HexomaticUserContext.Provider value={{hexomaticUser, setHexomaticUser}}>
        <Authentication>
          <div
            style={props.style && props.style}
            className={`${props.className ? `${props.className} ${className}` : className} ${
              props.top ? 'seo_home_area' : ''
            }`}
          >
            {props.sectionImage && isDesktop && <img className="section-img" src={sectionBgImg} />}
            {/* {props.sectionDots && isTablet && <img className="section-dots" src={sectionDotsImg} />} */}

            <div className="container">
              {props.title && (
                <div className="sec_title text-center mb_70 wow fadeInUp" data-wow-delay="0.2s">
                  <h2 className="f_p l_height48 f_600 t_color" style={{color: '#263b5e'}}>
                    {typeof props.title === 'string' ? <Translate name={props.title} /> : props.title}
                  </h2>
                  {props.subtitle && (
                    <p className="f_300 f_size_16 mb-0">
                      {typeof props.subtitle === 'string' ? <Translate name={props.subtitle} /> : props.subtitle}
                    </p>
                  )}
                </div>
              )}
              <div
                className={classNames('row', {
                  'justify-content-center': props.about,
                })}
              >
                {props.children}
              </div>
            </div>
          </div>
        </Authentication>
      </HexomaticUserContext.Provider>
    </UserContext.Provider>
  );
};

export default Section;
