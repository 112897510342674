import {useLazyQuery} from '@apollo/react-hooks';
import React, {useEffect, useContext} from 'react';
import {GET_HEXOMATIC_USER, ME} from '../../graphql/queries';
import {HexomaticUserContext} from '../../helpers/contexts/hexomatic-user';
import {UserContext} from '../../helpers/contexts/user-context';

const Authentication = (props: any) => {
  const {user, setUser} = useContext(UserContext);
  const {setHexomaticUser} = useContext(HexomaticUserContext);

  const [loadUserInfo, {data: userData}] = useLazyQuery(ME);

  const [hexomaticUserInfo, {data: hexomaticUserData}] = useLazyQuery(GET_HEXOMATIC_USER);

  useEffect(() => {
    loadUserInfo();
    hexomaticUserInfo();
  }, []);
  useEffect(() => {
    if (hexomaticUserData && hexomaticUserData.HexomaticUser && hexomaticUserData.HexomaticUser.getByLegacyId) {
      setHexomaticUser({...hexomaticUserData.HexomaticUser.getByLegacyId, photo: user?.photo});
    }
  }, [hexomaticUserData]);

  useEffect(() => {
    if (userData && userData.User && userData.User.get) {
      setUser({...userData.User.get, loaded: true});
    }
  }, [userData]);
  return <>{props.children}</>;
};

export default Authentication;
