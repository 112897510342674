import {createContext} from 'react';

export interface UserType {
  id: number;
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  companyWebsite: string;
  industry: string;
  countryUser: string;
  title: string;
  company: string;
  phone: string;
  logo?: string;
  mimeType?: string;
  error: boolean;
  loaded?: boolean;
  verified: boolean;
  hexometer_pricing_package: string;
  hexometer_pricing_package_interval: string;
  hexometer_pricing_package_count: number;
  shouldBeDeletedAt?: boolean;
  premium_credits_pricing_package: string;
  premium_credits_pricing_package_interval: string;
  left_premium_credits: number;
  left_one_time_premium_credits: number;
  photo: string;
  referral_code: string;
  premium_credit_subscription_status: 'active' | 'canceled';
  subscription_status: 'active' | 'canceled';
  premium_credit_subscription_cancel_at?: string;
}

export const UserContext = createContext({
  user: {} as null | UserType,
  setUser: (user: UserType) => {},
});
