import React, {Component} from 'react';
import {Translate} from '../../components/translations';
import TriangleOne from '../../images/triangle_one.png';
import TriangleTwo from '../../images/triangle_two.png';
import TriangleThree from '../../images/triangle_three.png';
import {PageHeroProps} from '../../types';
import Countdown from '../..//components/countdown';
import './styles.scss';

export class PageHero extends Component<PageHeroProps> {
  render() {
    const {
      title,
      titleSmall,
      subtitle,
      subtitle1,
      subtitle2,
      subtitle3,
      subtitle4,
      subtitle5,
      subtitleEl,
      children,
      countdown,
      leftSeats,
      noPaddingBottom,
      minHeight,
      className,
      id,
      nav,
    } = this.props;
    // const cookiePadding = getCookiePolicy() === "registered" ? 'no-cookie' : 'with-cookie'

    return (
      <section
        id={id || 'seo_home_area'}
        className={`seo_home_area special-section ${
          subtitle5 ? 'small-padding' : noPaddingBottom ? 'no-padding' : ''
        } ${minHeight ? 'min-height' : ''} ${className ? className : ''}`}
      >
        {/* <div className="home_bubble">
          <div className="bubble b_one" />
          <div className="bubble b_two" />
          <div className="bubble b_three" />
          <div className="bubble b_four" />
          <div className="bubble b_five" />
          <div className="bubble b_six" />
          <div className="triangle b_eight">
            <img src={TriangleTwo} alt="TriangleTwo" />
          </div>
          <div className="triangle b_nine">
            <img src={TriangleThree} alt="TriangleThree" />
          </div>
        </div> */}
        <div className="container">
          {nav}
          <div className="breadcrumb_content text-center">
            {title && (
              <h1 className={titleSmall ? `f_size_32 f_700 t_color4 l_height40    text-center` : 'page-hero-title'}>
                {typeof title === 'string' ? <Translate name={title} /> : title}
              </h1>
            )}

            {subtitle && (
              <p className={`f_300 page-hero-subTitle pricing-p-hero ${subtitle1 ? 'mb-0' : ''}`}>
                {typeof subtitle === 'string' ? <Translate name={subtitle} /> : subtitle}
              </p>
            )}
            {subtitleEl && subtitleEl}
            {subtitle1 && typeof subtitle1 === 'string' && (
              <p className="f_300 page-hero-subTitle mt-0">
                {typeof subtitle === 'string' ? <Translate name={subtitle1} /> : subtitle}
              </p>
            )}
            {subtitle2 && (
              <p className="f_400 page-hero-subTitleSmall">
                {typeof subtitle2 === 'string' ? <Translate name={subtitle2} /> : subtitle2}
              </p>
            )}
            {subtitle3 && (
              <p className="f_400 page-hero-subTitleSmall">
                {typeof subtitle3 === 'string' ? <Translate name={subtitle3} /> : subtitle3}
              </p>
            )}
            {subtitle4 && (
              <p className="f_400 page-hero-subTitleSmall">
                {typeof subtitle4 === 'string' ? <Translate name={subtitle4} /> : subtitle4}
              </p>
            )}
            {subtitle5 && (
              <p className="f_400 page-hero-subTitleSmall">
                {typeof subtitle5 === 'string' ? <Translate name={subtitle5} /> : subtitle5}
              </p>
            )}
            {countdown && (
              <Countdown
                date="Oct 17 2023 07:00:00 GMT"
                title="DEAL ENDS IN..."
                className="lg"
                // leftSeats={leftSeats}
                // hours
              />
            )}
            {children}
          </div>
        </div>
      </section>
    );
  }
}
