import React, { FC } from "react";
import { Translate } from "../../components/translations";
import Section from "../../components/section";
import { PageHero } from "../../components/page-hero";
import Layout from "../../components/layout/layout";

const CookiePolicy: FC = () => (
  <div>
    <Layout>
      <PageHero
        title="COOKIE_POLICY_PAGE_TITLE"
        subtitle="COOKIE_POLICY_PAGE_SUBTITLE"
        titleSmall
      />
      <Section>
        <div className="col-lg-12">
          <Translate name="COOKIE_POLICY_PAGE_CONTENT" />
        </div>
      </Section>
    </Layout>
  </div>
);

export default CookiePolicy;
